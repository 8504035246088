import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Divider from '../components/divider'
import LogoMain from '../components/logo-main'
import { isAndroid, isIOS, isMobile,  mobileModel } from 'react-device-detect'
import './blog-post.css'
import styled from 'styled-components'


class BlogPost extends Component {
  render() {
    const {
      activityType,
      numbering,
      title,
      speaker,
      heroImage,
      body,
      tags
    } = this.props.data.contentfulBlogPost
    let linkTo = this.props.data.contentfulBlogPost.linkTo

    let isiPhoneX = false
    if(mobileModel === 'iPhone') {
      // 2688 for Xs Max, 2436 for Xs, 1792 for Xr
      if(window.screen.height * window.devicePixelRatio === 2688 || window.screen.height * window.devicePixelRatio === 2436 || window.screen.height * window.devicePixelRatio === 1792) {
        isiPhoneX = true
      }
    }


    let btnLinkText = '원문 보기'
    if(activityType === 'Design Table') {
      // let device = 'desktop'
      if(isIOS) {
        // device = 'ios'
        linkTo = linkTo.slice(2, 3)
        btnLinkText = 'iTunes로 듣기'
      } else if(isAndroid) {
        // device = 'android'
        linkTo = linkTo.slice(1, 2)
        btnLinkText = '팟빵으로 듣기'
      } else {
        linkTo = linkTo.slice(0, 1)
        btnLinkText = '팟티로 듣기'
      }
      // console.log(mobileModel)
    } else if (linkTo !== null) {
      if (linkTo[0].includes('youtube')) {
        btnLinkText = '유튜브에서 보기'
      } else if (linkTo[0].includes('behance')) {
        btnLinkText = '비핸스에서 보기'
      } else if (linkTo[0].includes('brunch')) {
        btnLinkText = '브런치에서 보기'
      } else if (linkTo[0].includes('medium')) {
        btnLinkText = 'Medium에서 보기'
      }
    }

    if(linkTo !== null && linkTo[0].includes('youtube')) {
      btnLinkText = '유튜브로 보기'
    }

    function bodyHtml() {
      // to deal with null body
      if(body === null) {
        return (
          <div></div>
        );
      } else {
        return (
          <div dangerouslySetInnerHTML={{__html: body.childMarkdownRemark.html}}></div>
        );
      }
    }

    function embedVideo() {
      if ((!isMobile) && linkTo !== null && linkTo[0].includes('youtube')) {
        const videoId = linkTo[0].split('v=')[1].split('&')[0]
        const videoUrl = 'https://www.youtube.com/embed/' + videoId
        // console.log(videoId)
        return <iframe title={title} className='video-container' width="1000" height="562" src={videoUrl} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
      }
    }


    return (
      <Layout>
        <Helmet
          meta={[
            { name: 'title', content: title },
            // { property: 'og:type', content: "article" },
            { property: 'og:url', content: linkTo },
            { property: 'og:title', content: title },
            { property: 'og:description', content: tags },
            { property: 'og:image', content: heroImage.fluid.src },
          ]}
        >
          <title>{title}</title>
        </Helmet>

          <StyledTopWrapper>
            <LogoMain />
            {/* {!isMobile ? <LogoMain /> : <></>} */}

            <StyledTopContainer>

              <StyledCoverImage>
                <Img fluid={{...heroImage.fluid, aspectRatio: 1/1}} />
              </StyledCoverImage>

              <div className='sub-page-top-text-container'>
                <div className='sub-page-top-text-top'>
                  {/* <div className='sub-page-activity-logo'></div> */}
                  <div className='sub-page-activity-type'>{activityType}</div>
                  <div className='sub-page-numbering'>
                    {numbering.length === 1 ? numbering : numbering[0] + '\xa0\xa0' + numbering[1]}
                    {/* <span className='sub-page-activity-title-sub'>{'\xa0\xa0\xa0' + activityTitle}</span> */}
                  </div>
                  <div className='sub-page-title'>{title}</div>
                  <div className='sub-page-person'>{speaker}</div>
                </div>
                <div className='meaningless-horizontal-line'></div>
              </div>
            </StyledTopContainer>
          </StyledTopWrapper>

          <div className={isiPhoneX ? 'sub-page-body-container-for-iphone-x' : 'sub-page-body-container'}>
            <div className='divider-visibility'>
              <Divider />
            </div>

            <div className='sub-page-body'>
              {bodyHtml()}
              {embedVideo()}
            </div>
            <div className='sub-page-tag-container'>
              {
                tags !== null && tags.map((tag, i) => {
                  return (
                    <div className='sub-page-tag' key={'tag-'+i}>
                      #{tag}
                    </div>
                  );
                })
              }
              {/* {designedTags} */}
            </div>
            <a href={linkTo} target="_blank" rel="noopener noreferrer" className={linkTo === null ? 'hidden' : ''}>
              <div className='btn-link-container'>
                <div className={isiPhoneX ? 'btn-link-for-iphone-x' : 'btn-link'}>{btnLinkText}</div>
              </div>
            </a>
          </div>
        {/* </div> */}
      </Layout>
    );
  }
}

BlogPost.propTypes = {
  data: PropTypes.object.isRequired
}

const StyledTopWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 40px;

  @media only screen and (max-width: 600px) {
    padding-top: 22px;
  }
`

const StyledTopContainer = styled.div`
  padding-top: 80px;
  width: 84%;
  min-width: 740px;
  max-width: 1000px;
  padding-left: 48px;
  padding-right: 48px;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  @media only screen and (max-width: 600px) {
    padding: 0;
    width: 100%;
    min-width: 0;
    flex-direction: column;
  }
`

const StyledCoverImage = styled.div`
  width: 50%;
  max-width: 480px;
  max-height: 480px;

  @media only screen and (max-width: 600px) {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
    right: 0;
  }
`



export default BlogPost



export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: {eq: $slug}) {
      activityType
      numbering
      title
      slug
      speaker
      linkTo
      heroImage {
        fluid(maxWidth: 480, maxHeight: 480) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      tags
    },
    allContentfulActivityPage (
      sort: { fields: [activityNumber], order: ASC }
    ) {
      edges {
        node {
          logo {
            fluid(maxWidth: 80, maxHeight: 80) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
